.container{
    height: fit-content;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    padding-top: 50px;
}
.header{
    display: grid;
    grid-template-columns: 1fr 200px;
    align-items: flex-end;
}
.container h3{
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    opacity: 0.9;
}
.detailsHeader{
    height: fit-content;
    width: 100%;
    margin-top: 30px;
    display: grid;
    grid-template-columns: 250px 1fr 1fr 1fr 1fr;
    padding-bottom: 10px;
    border-bottom: 2px solid #dfdfdf;
}
.detailsHeader div{
    height: 100%;
    width: 100%;
    font-size: 18px;
    opacity: 0.8;
}
.status{
    display: flex;
    flex-direction: row-reverse;
}
.item{
    height: 70px;
    width: 100%;
    border-bottom: 2px solid #dfdfdf;
    display: grid;
    grid-template-columns: 250px 1fr 1fr 1fr 1fr;
    cursor: pointer;
    
}
.item:hover{
    animation: scaleup 0.5s ease forwards;
}
@keyframes scaleup {
    0%{transform: scale(1);}
    100%{transform: scale(1.02);}
}
.title{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.icon{
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: #dfdfdf;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 15px;
}
.title p{
    margin: 0;
    font-size: 18px;
    font-weight: 400;
}
.typeDiv, .typeDiv2, .typeDiv3{
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
}
.typeDiv p, .typeDiv3 p{
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    opacity: 0.9;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.typeDiv2 p{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.typeDiv3{
    justify-content: flex-end;
}
.typeDiv3 P{
    color: #daae00;
}
.emptyTransaction{
    padding-top: 50px;
    width: 100%;
    text-align: center;
}
.emptyTransaction p{
    font-size: 16px;
    opacity: 0.8;
}
@media (max-width: 700px) {
    .detailsHeader{
        grid-template-columns: 170px 1fr 1fr;
    }
    .item{
        grid-template-columns: 170px 1fr 1fr;
    }
    .dspNone{
        display: none;
    }
    .typeDiv{
        display: none;
    }
    .header{
        display: block;
    }
    .header div{
        display: none;
    }
}