.container{
    height: fit-content;
    width: 100%;
    background-color: #fff;
    border-radius: 7px;
    border: 1px solid #f3f3f3;
    padding: 20px;
    box-sizing: border-box;
}
.iconContainer{
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background-color: #f5f5f5;
    display: grid;
    align-items: center;
    justify-content: center;
}
.icon{
    opacity: 0.7;
}
.boxTitle{
    font-size: 16;
    /* font-weight: 500; */
    opacity: 0.6;
    margin: 0;
    margin-top: 13px;
    padding: 0;
    color: #000;
}
.value{
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    color: #000;
}
.container:hover{
    animation: scale 1s ease forwards;
}
@keyframes scale {
    0%{transform: scale(1);}
    100%{transform: scale(1.05);  box-shadow: 0 4px 6px rgba(0,0,0,0.08)}
}
.bottom{
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 20px;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
}
.eye{
    opacity: 0.5;
}