.dashboard{
    width: 100vw;
    background-color: #fafafa;
    height: 100vh;
    overflow: hidden;
}
.boxContainer{
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    padding-top: 30px;
}
.section{
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 50px;
}
@media (max-width: 900px) {
    .section{
        overflow: hidden;
    }
    .boxContainer{
        grid-template-columns: 1fr 1fr;
    }
}