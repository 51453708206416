.container{
    min-height: calc(100vh);
    border: 1px solid #000;
    padding-top: 100px;
    box-sizing: border-box;
    padding-bottom: 100px;
}
.container h2{
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
}
.container h2 span{
    color: var(--purple);
}
.intro{
    text-align: center;
    font-size: 16px;
    opacity: 0.8;
    width: 800px;
    margin: 0;
    margin-top: 7px;
    margin-right: auto;
    margin-left: auto;
}
.main{
    width: 900px;
    margin-right: auto;
    margin-left: auto;
    padding:  0 15px;
    padding-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: justify;
    grid-gap: 15px;
    box-sizing: border-box;
}
.main p{
    font-size: 16px;
    opacity: 0.8;
    margin: 0;
}
.breaker{
    margin-top: 10px;
}
.button{
    height: 50px;
    width: 200px;
    background-color: var(--purple);
    color: #000;
    font-size: 17px;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    margin-top: 20px;
    cursor: pointer;
    box-shadow: 0 5px 7px rgba(0,0,0,0.2);
    padding: 0;
}
.imgContainer{
    height: 100%;
    width: 100%;
}
.imgContainer img{
    width: 100%;
}
.modal{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    backdrop-filter: blur(5px);
    display: grid;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.modal img{
    max-height: 90vh;
    max-width: 90vw;
}
.extraDetails{
    width: 900px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 30px;
}
.extraDetails h3{
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
}
.extraDetails h3 span{
    color: var(--purple);
}
.extraDetails p{
    font-size: 16px;
    opacity: 0.8;
    margin: 0;
    padding-top: 10px;
}
@media (max-width: 830px) {
    .intro{
        width: calc(100vw - 30px);
    }
    .main{
        display: block;
        width: 100%;
    }
    .extraDetails{
        width: 100%;
        padding:  0 15px;
        box-sizing: border-box;
    }
}