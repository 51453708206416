.body{
    overflow-y: scroll;
    overflow-x: hidden;
}
.container{
    height: fit-content;
    padding: 0 15px;
    padding-top: 50px;
    padding-bottom: 100px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
.main{
    height: fit-content;
    width: 500px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 7px rgba(0,0,0,0.05);
    padding: 15px 15px;
    box-sizing: border-box;
}
@media (max-width: 600px) {
    .container{
        width: 100vw;
    }
    .main{
        width: 100%;
    }
}
.main h3{
    font-size: 20px;
    font-weight: 500;
    opacity: 0.8;
    margin: 0;
    margin-bottom: 15px;
}
.items{
    height: fit-content;
    border-top: 1px solid #dfdfdf;
    padding: 10px 0;
    color: rgba(0,0,0,0.8);
    display: grid;
    grid-template-columns: 20px 1fr;
    cursor: pointer;
}
.items:hover{
    transform: scale(1.05);
    animation: scaleUp 0.5s ease forwards;
}
.items p{
    margin: 0;
}
@keyframes scaleUp {
    0%{transform: scale(1);}
    100%{transform: scale(1.05);}
}