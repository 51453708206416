.container{
    padding-top: 100px;
}
.container h2{
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
}
.container h2 span{
    color: var(--purple);
}
.intro{
    text-align: center;
    font-size: 16px;
    opacity: 0.8;
    width: 800px;
    margin: 0;
    margin-top: 7px;
    margin-right: auto;
    margin-left: auto;
}
.main{
    width: 800px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 30px;
}
.item{
    height: fit-content;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 30px;
}
.item:hover{
    box-shadow: 0 4px 7px rgba(0,0,0,0.1);
}
.item h3{
    margin: 0;
    font-style: 20px;
    font-weight: 500;
    opacity: 0.9;
}
.item p{
    margin: 0;
    padding: 0;
    font-style: 16px;
    opacity: 0.7;
    margin-top: 10px;
}
@media (max-width: 830px) {
    .intro{
        width: calc(100vw - 30px);
    }
    .main{
        display: block;
        width: 100%;
        padding: 30px 15px;
        box-sizing: border-box;
    }
}