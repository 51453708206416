.container{
    height: fit-content;
    width: 800px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 70px;
}
@media (max-width: 830px) {
    .container{
        width: 100%;
        padding: 0 15px;
        padding-top: 70px;
        box-sizing: border-box;
    }
}
.container h2{
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
}
.container h2 span{
    color: var(--purple);
}
.content{
    font-size: 16px;
    margin: 0;
    padding-top: 20px;
    opacity: 0.8;
}
.writer{
    font-size: 18px;
    margin: 0;
    font-weight: 500;
    margin-top: 10px;
    opacity: 0.9;
}