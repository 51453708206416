.homePage{
    width: 100vw;
    background-color: #fafafa;
}
.footer{
    height: 100px;
    width: 100%;
    background-color: #fafafa;
    margin-top: 50px;
    display: grid;
    align-items: center;
    justify-content: center;
}
.footer p{
    opacity: 0.8;
}
.links{
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
}
.links a{
    text-decoration: none;
    color: black;
}
.link_item{
    height: fit-content;
    width: fit-content;
    padding: 10px 10px;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    background-color: #ddd;
    font-size: 14px;
    font-weight: 500;
    padding-right: 15px;
    text-decoration: none;
}
.link_item img{
    height: 30px;
}