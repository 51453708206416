.container{
    width: 100%;
    padding-top: 70px;
}
.container h2{
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
}
.container h2 span{
    color: var(--purple);
}
.intro{
    text-align: center;
    font-size: 16px;
    opacity: 0.8;
    width: 800px;
    margin: 0;
    margin-top: 7px;
    margin-right: auto;
    margin-left: auto;
}
.main{
    width: 800px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}
.itemCotnainer{
    height: 100%;
}
.item{
    width: 100%;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 10px;
    margin-bottom: 20px;
    align-items: center;
    cursor: pointer;
}
.item .icon, .icon2, .icon3, .icon4{
    height: 40px;
    width: 40px;
    border-radius: 5px;
    background-color: rgb(221, 221, 255);
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: rgb(93, 93, 255);
}
.icon2{
    background-color: rgb(255, 189, 189);
    color: #ff5656;
}
.icon3{
    background-color: rgb(255, 226, 153);
    color: rgb(206, 218, 32);
}
.icon4{
    background-color: rgb(155, 255, 155);
    color: rgb(15, 197, 15);
}
.item p{
    margin: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
}
.item p span{
    color: rgba(0, 0, 0, 0.74);
    font-weight: 600;
}
.inputsContainer{
    height: 100%;
    width: 100%;
}
.inputsContainer p{
    margin: 0;
    font-size: 16px;
    margin-bottom: 8px;
}
.inputsContainer textarea{
    height: 150px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #dfdfdf;
    margin-bottom: 30px;
}
.inputsContainer textarea:focus{
    outline: none;
    border: 1px solid var(--purple);
}
@media (max-width: 830px) {
    .intro{
        width: calc(100vw - 30px);
    }
    .main{
        display: block;
        width: 100%;
        padding: 0 15px;
        padding-top: 30px;
        box-sizing: border-box;
    }
    .itemCotnainer{
        margin-bottom: 50px;
        padding-top: 20px;
    }
}