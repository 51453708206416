.container{
    height: 100vh;
    width: 100vw;
    background-color: #fafafa;
    overflow: hidden;
    display: grid;
    grid-template-columns: 250px 1fr;
    position: fixed;
    top: 0;
    left: 0;
}
.sidebar{
    height: 100vh;
    width: 100%;
    background-color: #fff;
    display: grid;
    grid-template-rows: 100px 1fr 70px;
    padding: 0 15px;
    box-sizing: border-box;
    z-index: 300;
}
.Sidebarlogo{
    height: 70px;
    width: 100%;
}
.sidebarLinks{
    width: 100%;
    box-sizing: border-box;
}
.navLink{
    height: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: 20px 1fr;
    column-gap: 10px;
    font-size: 20px;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 5px;
    opacity: 0.3;
    cursor: pointer;
    margin-bottom: 15px;
}
.activeLink{
    opacity: 1;
    color: var(--purple);
    background-color: #f5f5f5;
    border: 1px solid #dfdfdf;
}
.logoutLink{
    opacity: 1;
    color: rgb(255, 57, 57);
}
.navLink p{
    margin: 0;
    font-size: 17px;
    font-weight: 500;
}
.logoContainer{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    grid-gap: 10px;
    cursor: pointer;
}
.logoContainer img{
    height: 20px;
}
.logoContainer p{
    font-size: 16px;
    margin: 0;
    font-weight: 500;
}
.logoContainer p span{
    color: var(--purple);
}
@media (max-width: 900px) {
    .container{
        display: block;
        overflow-y: scroll;
    }
    .sidebar{
        display: none;
    }
}