.container{
    width: 100%;
    padding: 0 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 70px;
    box-sizing: border-box;
}
/* @media (max-width: 830px) {
    .container{
        
    }
} */
.container h2{
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
}
.container h2 span{
    color: var(--purple);
}
.intro{
    text-align: center;
    font-size: 16px;
    opacity: 0.8;
    width: 800px;
    margin: 0;
    margin-top: 7px;
    margin-right: auto;
    margin-left: auto;
}
.main{
    width: 900px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: justify;
    grid-gap: 15px;
}
.main p{
    font-size: 16px;
    opacity: 0.9;
    margin: 0;
}
.button{
    height: 50px;
    width: 200px;
    background-color: var(--purple);
    color: #000;
    font-size: 17px;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    margin-top: 20px;
    cursor: pointer;
    box-shadow: 0 5px 7px rgba(0,0,0,0.2);
    padding: 0;
}
.imgContainer{
    height: 100%;
    width: 100%;
}
.imgContainer img{
    width: 100%;
}
@media (max-width: 830px) {
    .intro{
        width: calc(100vw - 30px);
    }
    .main{
        display: block;
        width: 100%;
    }
}