.container{
    height: 70px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 5px rgba(0,0,0,0.05);
    display: grid;
    grid-template-columns: 300px 1fr;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;
}
.logoContainer{
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}
.icon{
    font-size: 25px;
    padding-right: 15px;
    display: none;
}
.logoContainer h2{
    margin: 0;
    display: block;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
}
.userDetails{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.userDetails p{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}
.userDetails p span{
    opacity: 0.5;
    font-size: 16px;
    font-weight: 400;
}
.userImage{
    height: 40px;
    width: 40px;
    border-radius: 23px;
    background-color: #f5f5f5;
    margin-left: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
@media (max-width: 900px) {
    .container{
        grid-template-columns: 50px 1fr;
    }
    .logoContainer h2{
        display: none;
    }
    .icon{
        display: block;
    }
}
.sideBar{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    z-index: 300;
    display: grid;
    grid-template-columns: 1fr 300px;

}
.sideBarBackground{
    width: 100%;
    background-color: transparent;
    height: 100%;
}
.mainSidebar, .closeSideBar{
    height: 100vh;
    width: 100%;
    background-color: #fff;
    animation: slideIn 0.8s ease forwards;
    padding: 20px 15px;
    box-sizing: border-box;
}
.closeSideBar{
    animation: slideOut 0.6s ease forwards;
}
@keyframes slideOut {
    0%{transform: translateX(0);}
    100%{transform: translateX(300px);}
}
@keyframes slideIn {
    0%{transform: translateX(300px);}
    100%{transform: translateX(0);}
}
.closeIcon{
    width: 100%;
    height: fit-content;
    display: grid;
    justify-content: flex-end;
    margin-bottom: 30px;
}