.container{
    height: fit-content;
    width: 100%;
    padding-top: 50px;
    margin-bottom: -200px;
}
.container h2{
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
}
.container h2 span{
    color: var(--purple);
}
.intro{
    text-align: center;
    font-size: 16px;
    opacity: 0.8;
    width: 800px;
    margin: 0;
    margin-top: 7px;
    margin-right: auto;
    margin-left: auto;
}
.main{
    height: fit-content;
    width: 400px;
    background-color: #fff;
    border-radius: 10px;
    margin-right: auto;
    margin-left: auto;
    padding: 20px;
    box-sizing: border-box;
    display: grid;
    gap: 25px;
    position: relative;
    top: -180px;
}
.main:hover{
    animation: scaleup 0.6s ease forwards;
}
@keyframes scaleup {
    100%{box-shadow: 0 4px 10px rgba(0,0,0,0.2);}
}
.item{
    width: 100%;
}
.itemMain{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-right: auto;
    margin-left: auto;
    width: fit-content;
    align-items: center;
}
.itemMain p{
    margin: 0;
    font-size: 23px;
    font-weight: 500;
    width: fit-content;
    opacity: 0.9;
}
.icon{
    font-size: 23px;
    color: var(--purple);
}
.itemTitle{
    font-size: 16px;
    text-align: center;
    opacity: 0.7;
    margin: 0;
    margin-top: 5px;
}
.canva{
    height: 200px;
    width: 600px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
}
.canva img{
    height: 100%;
    opacity: 0.5;
    animation: rotate 20s linear infinite;
}
@keyframes rotate {
    100%{
        transform: rotate(360deg);
    }
}
@media (max-width: 700px) {
    .container h2{
        width: calc(100vw - 30px);
    }
    .intro{
        width: calc(100vw - 30px);
    }
    .main{
        width: calc(100vw - 30px);
    }
}