.container{
    height: 500px;
    width: 100%;
    padding: 50px 15px;
    box-sizing: border-box;
    display: grid;
    justify-content: center;
}
.main{
    height: fit-content;
    width: 300px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 7px rgba(0,0,0,0.05);
    padding: 20px 15px;
    box-sizing: border-box;
}

.main select{
    height: 40px;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 7px;
    border: 1px solid #dfdfdf;
    margin-bottom: 15px;
    padding-left: 7px;
}
.selectInput{
    height: 40px;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 7px;
    border: 1px solid #dfdfdf;
    display: grid;
    grid-template-columns: 1fr 20px;
    cursor: pointer;
    margin-bottom: 20px;
}
.selectInput:hover{
    border: 1px solid var(--purple);
}
.dropDown{
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #000;
}
.selectInputMain{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.currencyicon{
    height: 25px;
    width: 25px;
    border-radius: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 10px;
}
.selectInputMain p{
    font-size: 16px;
    margin: 0;
}
.inputMain{
    width: 100%;
}
.inputMain p{
    margin: 0;
    font-size: 14px;
    opacity: 0.7;
}
.inputMain input{
    height: 40px;
    width: 100%;
    border: 1px solid #dfdfdf;
    background-color: #f5f5f5;
    box-sizing: border-box;
    font-size: 16px;
    margin-top: 5px;
    border-radius: 7px;
    padding-left: 10px;
    margin-bottom: 30px;
}
.inputMain input:focus, .main select:focus{
    outline: none;
    border: 1px solid var(--purple);
}
.note{
    margin: 0;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 20px;
}
.backgroundModal{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 400;
    display: grid;
    align-content: center;
    justify-content: center;
}
.maniModal{
    height: fit-content;
    width: 280px;
    background-color: #fff;
    border-radius: 10px;
    animation: slideDown 0.5s ease forwards;
    z-index: 401;
    padding: 10px 15px;
    box-sizing: border-box;
    max-height: calc(100vh - 100px);
    overflow: hidden;
    padding-bottom: 20px;
}
.main_items{
    overflow-y: scroll;
    max-height: 350px;
}
.main_items::-webkit-scrollbar {
    display: none;
}
@keyframes slideDown {
    0%{transform: translateY(-300px);}
    100%{transform: translateY(0);}
}
.modalTitle{
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 20px;
    padding-bottom: 10px;
}
.modalTitle h3{
    font-size: 20px;
    font-weight: 400;
    opacity: 0.8;
    margin: 0;
    padding: 0;
}
.CurrencyModalItem{
    height: 40px;
    width: 100%;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    padding: 0 7px;
    box-sizing: border-box;
}
.CurrencyModalItem:hover{
    background-color: #f5f5f5;
}
.CurrencyModalItem div{
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background-color: #f5f5f5;
    margin-right: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.CurrencyModalItem p{
    font-size: 16px;
    margin: 0;
}
@media (max-width: 450px) {
    .main{
        width: 100%;
    }
    .maniModal{
        width: calc(100vw - 30px);
    }
}