.container{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.3);
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1100;
    display: grid;
    align-items: center;
    justify-content: center;
}
.maincontainer{
    width: 400px;
}
.main{
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    padding: 10px 15px;
    box-sizing: border-box;
}
@media (max-width: 450px) {
    .maincontainer{
        width: calc(100% - 30px);
        padding: 0 15px;
    }
}
.divTitle{
    margin: 0;
    font-size: 15px;
    color: #000;
    opacity: 0.8;
    text-align: center;
}
.top{
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-top: 20px;
}
.top img{
    height: 40px;
    width: 40px;
    border-radius: 40px;
    object-fit: cover;
    display: grid;
    margin-right: auto;
    margin-left: auto;
}
.amount_div{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: baseline;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    padding: 15px 0;
}
.amount_div .amount{
    font-size: 23px;
    font-family: sans-serif;
    font-weight: 500;
    margin: 0;
}
.amount_div .amount_type{
    font-size: 16px;
    opacity: 0.8;
    margin: 0;
}
.items_container{
    width: 100%;
    padding-top: 20px;
}
.item{
    width: 100%;
    display: grid;
    grid-template-columns: 130px 1fr;
    gap: 15px;
    font-family: sans-serif;
    margin-bottom: 20px;
}
.item_1{
    height: 100%;
    width: 100%;
    color: rgba(0, 0, 0, 0.6);
}
.item_2{
    display: flex;
    flex-direction: row-reverse;
    color: rgba(0, 0, 0, 0.9);
    overflow: hidden;
    text-align: right;
    text-justify: inter-word;
}
.item_2 p{
    margin: 0;
    word-wrap: break-word;
    word-break: break-all;
    text-align-last: right;
    line-height: 150%;
}
.cancle_div{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    color: #fff;
    font-size: 25px;
    cursor: pointer;
    padding-bottom: 10px;
}
.capitailize{
    text-transform: capitalize;
}