.container{
    height: 100vh;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
}
.container p{
    font-size: 17px;
    font-weight: 500;
    opacity: 0.8;
    margin: 0;
}