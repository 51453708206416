.authPage{
    width: 100vw;
    background-color: #fafafa;
    padding-bottom: 30px;
}
.container{
    height: 100vh;
    width: 1000px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.formContainer, .signupFormContainer{
    width: 350px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 100px;
}
.formContainer h2, .signupFormContainer h2{
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
}
.signupFormContainer{
    padding-top: 30px;
}
.intro{
    font-size: 15px;
    opacity: 0.9;
    margin: 0;
    margin-top: 7px;
    margin-right: auto;
    margin-left: auto;
}
.mainFormContainer{
    padding-top: 25px;
}
.inputItem, .PasswordinputItem{
    width: 100%;
    margin-bottom: 15px;
}
.inputItem p, .PasswordinputItem{
    margin: 0;
    font-size: 16px;
    margin-bottom: 8px;
}
.inputItem input, .inputItem select{
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    font-size: 16px;
    padding-left: 10px;
}
.inputItem input:focus, .inputItem input:hover, .inputItem select:focus, .inputItem select:hover{
    outline: none;
    border: 1px solid var(--purple);
}
.passInput{
    height: 40px;
    width: 100%;
    border: 1px solid #dfdfdf;
    background-color: #fff;
    border-radius: 3px;
    display: grid;
    grid-template-columns: 1fr 50px;
}
.passInput:hover, .passInput:focus-within{
    border: 1px solid var(--purple);
}
.passInput input{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-size: 16px;
    padding-left: 10px;
    border: none;
}
.passInput input:focus{
    border: none;
    outline: none;
}
.eyeContainer{
    width: 100%;
    height: 100%;
    display: grid;
    align-content: center;
    justify-content: center;
}
.authButton{
    height: 45px;
    width: 100%;
    background-color: var(--purple);
    margin-top: 30px;
    color: #000;
    font-size: 16px;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    border: none;
    box-shadow: 0 4px 7px rgba(0,0,0,0.1);
    text-transform: capitalize;
}
.forgotPassword, .extraLink2{
    font-size: 16px;
    color: red;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 0;
}
.extraLink{
    font-size: 16px;
    color: red;
    margin-top: 10px;
    cursor: pointer;
}


/*************** modals styles *************/
.modalBackground{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    z-index: 1000;
    backdrop-filter: blur(5px);
    display: grid;
    align-items: center;
    justify-content: center;
}
.modalMain{
    height: fit-content;
    width: 350px;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    animation: drop 0.7s ease forwards;
}
@keyframes drop {
    0%{transform: translateY(-300px);}
    100%{transform: translateY(0);}
}
.mainModalHeader{
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 10px;
}
.mainModalHeader h3{
    margin: 0;
    font-style: 22px;
    font-weight: 400;
    opacity: 0.9;
    font-weight: 500;
}
.verifyemailcontent{
    font-style: 16px;
    margin: 0;
    margin-top: 10px;
    opacity: 0.7;
    margin-bottom: 20px;
}
.verifyemailcontent span{
    color: var(--purple);
    font-weight: 500;
    cursor: pointer;
}

.errorScreenMain{
    height: fit-content;
    width: 300px;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 15px;
    box-sizing: border-box;
    animation: drop 0.7s ease forwards;
}
.errorIcon{
    height: 80px;
    width: 80px;
    border-radius: 40px;
    background-color: rgba(255, 0, 0, 0.2);
    display: grid;
    align-items: center;
    justify-content: center;
    color: rgba(255, 0, 0, 0.5);
    font-size: 50px;
    margin-right: auto;
    margin-left: auto;
}
.errorScreenMain h3{
    margin: 0;
    text-align: center;
    font-weight: 500;
    margin-top: 10px;
}
.errorScreenMain p{
    font-size: 16px;
    margin: 0;
    opacity: 0.7;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 20px;
}
.rightAnimation{
    height: 100%;
    /* width: 100%; */
    display: grid;
    align-items: center;
    justify-content: center;
}
@media (max-width: 830px) {
    .animationDiv{
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding: 0 15px;
        box-sizing: border-box;
    }
    .container{
        width: 100%;
        display: block;
        padding-bottom: 50px;
    }
    .modalMain, .errorScreenMain{
        width: calc(100vw - 30px);
    }
}
