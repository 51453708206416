.container{
    width: 100%;
    padding-top: 50px;
}
.container h2{
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
}
.container h2 span{
    color: var(--purple);
}
.intro{
    text-align: center;
    font-size: 16px;
    opacity: 0.8;
    width: 800px;
    margin: 0;
    margin-top: 7px;
    margin-right: auto;
    margin-left: auto;
}
.item{
    height: 250px;
    width: 250px;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    cursor: pointer;
}
.item:hover{
    animation: scaleup 0.6s ease forwards;
}
@keyframes scaleup {
    100%{box-shadow: 0 4px 10px rgba(0,0,0,0.2);}
}
.itemsContainer{
    padding-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 30px;
}
.item .profile{
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background-color: #f5f5f5;
    margin-right: auto;
    margin-left: auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.item .username{
    font-size: 18px;
    text-align: center;
    margin: 0;
    margin-top: 5px;
    opacity: 0.8;
}
.content{
    font-size: 15px;
    margin: 0;
    opacity: 0.5;
    margin-top: 10px;
}
@media (max-width: 830px) {
    .intro{
        width: calc(100vw - 30px);
    }
    .itemsContainer{
        display: block;
        width: 100%;
        padding: 0 15px;
        padding-top: 30px;
        box-sizing: border-box;
    }
    .item{
        width: 100%;
        margin-bottom: 30px;
        height: fit-content;
    }
}