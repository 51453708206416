.container{
    height: fit-content;
    width: 100%;
    padding-top: 50px;
}
.container h2{
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
}
.container h2 span{
    color: var(--purple);
}
.intro{
    text-align: center;
    font-size: 16px;
    opacity: 0.8;
    width: 800px;
    margin: 0;
    margin-top: 7px;
    margin-right: auto;
    margin-left: auto;
}
.itemsContainer{
    padding-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 30px;
}
.Item{
    height: 300px;
    width: 250px;
    border-radius: 10px;
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    cursor: pointer;
}
.Item:hover{
    animation: scaleup 1s ease forwards;
}
@keyframes scaleup {
    100%{box-shadow: 0px 0px 10px rgba(0,0,0,0.2);}
}
.mainItem{
    height: 100%;
    width: 100%;
    background-color: hsla(48, 97%, 60%, 0.607);
    overflow: hidden;
    display: grid;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    animation: fadein 0.7s ease forwards;
}
@keyframes fadein {
    0%{opacity: 0;}
    100%{opacity: 1;}
}
.name{
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}
.position{
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    opacity: 0.9;
    margin-top: 5px;
}
@media (max-width: 830px) {
    .intro{
        width: calc(100vw - 30px);
    }
    .itemsContainer{
        display: block;
        width: 100%;
        padding: 0 15px;
        padding-top: 30px;
        box-sizing: border-box;
    }
    .Item{
        width: 100%;
        height: 400px;
        margin-bottom: 30px;
    }
}