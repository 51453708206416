.container{
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
    padding-top: 50px;
}
.container h2{
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
}
.container h2 span{
    color: var(--purple);
}
.intro{
    text-align: center;
    font-size: 16px;
    opacity: 0.8;
    width: 800px;
    margin: 0;
    margin-top: 7px;
    margin-right: auto;
    margin-left: auto;
}
.main{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
    padding-top: 50px;
}
.item{
    /* height: fit-content; */
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    /* border: 1px solid gold; */
    box-sizing: border-box;
    padding: 15px 15px;
    cursor: pointer;
}
.item:hover{
    animation: scale 0.7s ease forwards;
}
@keyframes scale {
    0%{transform: scale(1)}
    100%{transform: scale(1.05); box-shadow: 0 0 14px rgba(0,0,0,0.15);}
}
.item img{
    width: 50px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    display: grid;
}
.item h2{
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: 0.8;
}
.item p{
    margin: 0;
    opacity: 0.7;
    font-style: 16px;
    line-height: 150%;
}
.percentageContainer{
    height: 400px;
    width: calc(100vw - 30px);
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-right: auto;
    margin-left: auto;
    /* background-color: cornsilk; */
}
.mainPercentage{
    height: 400px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
}
.mainContainer{
    width: 450px;
}
.percentItem{
    width: 100%;
    margin-bottom: 20px;
}
.percentItemTitle{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}
.percentItemDiv{
    height: 25px;
    width: 100%;
    background-color: #eeeeee;
    margin-top: 10px;
    border-radius: 0px;
    overflow: hidden;
}
.percentItemMain{
    height: 100%;
    background-color: var(--purple);
    border-radius: 0px;
    display: grid;
    align-items: center;
    justify-content: flex-end;
    color: #000;
    font-size: 15px;
    padding-right: 10px;
    box-sizing: border-box;
}
.percImage{
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
}
.percImage img{
    max-height: 100%;
}
@media (max-width: 830px) {
    .intro{
        width: calc(100vw - 30px);
    }
    .main{
        display: block;
    }
    .item{
        margin-bottom: 30px;
    }
    .percentageContainer{
        display: block;
    }
    .mainContainer{
        width: calc(100vw - 30px);
    }
    .percImage{
        display: none;
    }
}