.container{
    height: 40px;
    width: 100%;
    background-color: var(--purple);
    color: #000;
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    border-radius: 7px;
    box-shadow: 0 4px 7px rgba(0,0,0,0.1);
}