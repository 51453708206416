.container{
    height: 70px;
    width: 100vw;
    background-color: #fff;
    padding: 0 15px;
    padding-right: 30px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 250px 1fr;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    box-shadow: 0 4px 5px rgba(0,0,0,0.05);
}
.logoContainer{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    grid-gap: 10px;
}
.logoContainer img{
    height: 30px;
}
.logoContainer p{
    font-size: 18px;
    margin: 0;
    font-weight: 500;
}
.logoContainer p span{
    color: var(--purple);
}
.linksContainer{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.linksContainer p{
    margin: 0;
    color: #000;
    font-size: 16px;
    /* font-weight: 500; */
    margin-left: 40px;
    cursor: pointer;
}
.linksContainer p:hover, .authButton2:hover{
    transform: scale(1.05);
    color: var(--purple);
}
.hamburgeIcon{
    height: 100%;
    display: grid;
    align-items: center;
    display: none;
    cursor: pointer;
}
.authButtonsContainer{
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    margin-left: 50px;
}
.authButton, .authButton3, .authButton4{
    height: 35px;
    width: 110px;
    background-color: var(--purple);
    border-radius: 5px;
    display: grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.authButton2{
    background-color: #fff;
}
.authButton3{
    width: 100%;
    font-size: 20px;
    height: 43px;
    margin-top: 50px;
    background-color: #fff;
}
.authButton4{
    width: 100%;
    font-size: 20px;
    height: 43px;
    margin-top: 20px;
}
@media (max-width: 950px) {
    .container{
        grid-template-columns: 1fr 50px;
        padding-right: 15px;
    }
    .linksContainer p{
        display: none;
    }
    .authButtonsContainer{
        display: none;
    }
    .hamburgeIcon{
        display: grid;
    }
}
.sideBar{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    z-index: 300;
    display: grid;
    grid-template-columns: 1fr 300px;

}
.sideBarBackground{
    width: 100%;
    background-color: transparent;
    height: 100%;
}
.mainSidebar, .closeSideBar{
    height: 100vh;
    width: 100%;
    background-color: #fff;
    animation: slideIn 0.8s ease forwards;
    padding: 20px 15px;
    box-sizing: border-box;
}
.closeSideBar{
    animation: slideOut 0.6s ease forwards;
}
@keyframes slideOut {
    0%{transform: translateX(0);}
    100%{transform: translateX(300px);}
}
@keyframes slideIn {
    0%{transform: translateX(300px);}
    100%{transform: translateX(0);}
}
.closeIcon{
    width: 100%;
    height: fit-content;
    display: grid;
    justify-content: flex-end;
    margin-bottom: 30px;
}
.item{
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    font-size: 22px;
    align-items: center;
    margin-bottom: 5px;
    opacity: 0.7;
}
.item p{
    margin: 0;
    font-size: 20px;
    padding-left: 15px;
}