.container{
    width: 100vw;
    padding-top: 50px;
}
.container h2{
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
}
.container h2 span{
    color: var(--purple);
}
.main{
    height: fit-content;
    width: 400px;
    background-color: #fff;
    border-radius: 10px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    border: 1px solid rgba(0,0,0,0.05);
    padding: 20px;
    box-sizing: border-box;
}
@media (max-width: 450px) {
    .main{
        width: calc(100vw - 30px);
    }
}
.firstInput{
    width: 100%;
}
.inputTitle{
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}
.firstInput input{
    height: 35px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #fafafa;
    border: 1px solid #dfdfdf;
    padding-left: 15px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.8);
    margin-top: 10px;
}
.convertGrid{
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 50px 1fr;
    gap: 20px;
}
.from{
    width: 100%;
}
.from select{
    height: 35px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #fafafa;
    border: 1px solid #dfdfdf;
    margin-top: 10px;
    text-transform: uppercase;
}
.firstInput input:focus, .from select:focus{
    outline: none;
}
.convertIcon{
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 400;
    opacity: 0.5;
    padding-top: 20px;
    box-sizing: border-box;
}
.results{
    height: 30px;
    width: 100%;
    margin: 20px 0;
    text-align: center;
}
.results p{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    opacity: 0.9;
    text-transform: uppercase;
}
.convert{
    height: 40px;
    width: 150px;
    border-radius: 5px;
    background-color: var(--purple);
    color: #000;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
}