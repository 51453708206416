.App {
  overflow: hidden;
  background-color: #fafafa;
}
.Mobile{
  height: 100vh;
  width: 100%;
  text-align: center;
}
.Mobile img{
  height: 500px;
}
.Mobile p{
  font-weight: 600;
  font-size: 18px;
}
:root{
  --purple: #FCD535;
  --purple2: #ffdf5f;
}
