.container{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    z-index: 1000;
    backdrop-filter: blur(5px);
    display: grid;
    align-items: center;
    justify-content: center;
}
.container .main{
    height: fit-content;
    width: 300px;
    background-color: #fff;
    border-radius: 14px;
    padding: 15px;
    box-sizing: border-box;
    padding-top: 0;
    text-align: center;
}
.container .main h3{
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}
.container .main p{
    font-size: 16px;
    opacity: 0.7;
    line-height: 150%;
}
@media (max-width: 450px) {
    .container .main{
        width: calc(100vw - 30px);
    }
}