.section{
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 100px;
}
.container{
    width: 400px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 30px;
}
@media (max-width: 450px) {
    .container{
        width: 100%;
        padding: 30px 15px;
        box-sizing: border-box;
    }
}
.main1{
    height: fit-content;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 7px rgba(0,0,0,0.05);
    padding: 15px 15px;
    box-sizing: border-box;
}
.main1 h3, .referallsDiv h3{
    font-weight: 500;
    margin: 0;
}
.referallsDiv h3{
    padding-bottom: 20px;
}
.mainContainer{
    height: 140px;
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    display: grid;
}
.mainContainer .number{
    margin: 0;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
}
.main1p{
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    text-align: center;
    margin-top: 5px;
    opacity: 0.7;
}
.main1 h4{
    margin: 0;
    font-weight: 500;
    /* font-size: 16px; */
    margin-top: 15px;
    opacity: 0.9;
    line-height: 150%;
}
.text{
    font-size: 14px;
    opacity: 0.8;
    line-height: 170%;
    margin-bottom: 0;
}
.codeContainer{
    padding-top: 15px;
    padding-bottom: 5px;
    width: 100%;
    border-top: 1px solid #dfdfdf;
    margin-top: 15px;
}
.codeContainer .codeP1{
    font-weight: normal;
    font-size: 15px;
    opacity: 0.5;
    margin: 0;
    margin-bottom: 5px;
}
.codeContainer .codeP2{
    font-weight: 500;
    font-size: 16px;
    opacity: 0.9;
    margin: 0;
}
.referallsDiv{
    width: 100%;
    margin-top: 30px;
}
.item{
    height: 60px;
    width: 100%;
    display: grid;
    grid-template-columns: 40px 1fr;
    column-gap: 10px;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: #fff;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 15px;
}
.profile{
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: var(--purple);
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
}
.item p{
    font-size: 18px;
    font-weight: 500;
    opacity: 0.9;
    margin: 0;
}